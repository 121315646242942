import { Nav } from './Nav';
import { ReactComponent as Beoble } from '../assets/beoble-logo.svg';
import { useNavigate } from 'react-router-dom';
import { NavMobile } from './NavMobile';

export const Header = ({ hideLogo }: { hideLogo?: boolean }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`mx-0 md:mx-6 my-2 mb-4 flex md:flex-row items-center ${
        !hideLogo ? 'justify-between' : 'justify-end'
      } relative`}
    >
      {!hideLogo && (
        <Beoble
          className="cursor-pointer md:mb-0"
          onClick={() => navigate('/')}
        />
      )}
      <NavMobile />
      <Nav />
    </div>
  );
};
