import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate: any = new Date(Date.UTC(2023, 11, 2, 4));
    const interval = setInterval(() => {
      const now: any = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {countdown.days !== 0 &&
        countdown.hours !== 0 &&
        countdown.minutes !== 0 && (
          <p className="text-2xl font-bold">
            beta will start in {countdown.days} days, {countdown.hours} hrs,{' '}
            {countdown.minutes} min, {countdown.seconds} secs ⏰
          </p>
        )}
    </div>
  );
};

export default CountdownTimer;
