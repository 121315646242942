import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const NavMobile = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const [currentHref, setCurrentHref] = useState('');

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    setCurrentHref(window.location.pathname);
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    const handleDocumentClick = (e: any) => {
      if (menuRef.current && !(menuRef.current as any).contains(e.target)) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleDocumentClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <>
      {isMobileMenuOpen && (
        <div className="absolute top-full text-center right-[1px] left-0 md:hidden z-50 bg-white rounded-xl shadow-md p-2 ">
          <p
            className={`mb-1 ${
              currentHref === '/investors' &&
              'bg-[#486AF633] p-[8px] px-[16px] rounded-[12px]'
            }`}
            onClick={() => {
              navigate('/investors');
              toggleMobileMenu();
            }}
          >
            Investors
          </p>
          <p
            className={`mb-1`}
            onClick={() => {
              window.open('https://docs.beoble.io/', '_blank');
              toggleMobileMenu();
            }}
          >
            Docs
          </p>
          {/* <p
            className={`mb-1  px-[16px] rounded-[12px] ${
              currentHref === '/teams' && ' p-[8px] bg-[#486AF633]'
            }`}
            onClick={() => {
              navigate('/teams');
              toggleMobileMenu();
            }}
          >
            Team
          </p> */}
          <p
            className="mb-1"
            onClick={() => {
              window.open('https://twitter.com/beoble_official', '_blank');
              toggleMobileMenu();
            }}
          >
            Twitter
          </p>
          <p
            className={`mb-1 ${
              currentHref === '/faqs' &&
              'bg-[#486AF633] p-[8px] px-[16px] rounded-[12px]'
            }`}
            onClick={() => {
              navigate('/faqs');
              toggleMobileMenu();
            }}
          >
            FAQs
          </p>
        </div>
      )}
      <div
        ref={menuRef}
        className="md:hidden text-px24"
        onClick={toggleMobileMenu}
      >
        ☰
      </div>
    </>
  );
};
