import { useNavigate } from 'react-router-dom';

export const Nav = () => {
  let navigate = useNavigate();

  return (
    <div className="font-helvetica hidden md:flex text-night-light flex  md:mt-0">
      {/* Desktop menu items */}
      <p
        onClick={() => navigate('/investors')}
        className="text-night-light mr-2 cursor-pointer font-bold hover:text-night"
      >
        Investors
      </p>
      <p
        onClick={() => window.open('https://docs.beoble.io/', '_blank')}
        className="text-night-light mr-2 cursor-pointer font-bold hover:text-night"
      >
        Docs
      </p>
      {/* <p
        onClick={() => navigate('/teams')}
        className="text-night-light mr-2 cursor-pointer font-bold hover:text-night"
      >
        Team
      </p> */}
      <p
        onClick={() =>
          window.open('https://twitter.com/beoble_official', '_blank')
        }
        className="text-night-light mr-2 cursor-pointer font-bold hover:text-night"
      >
        Twitter
      </p>
      <p
        onClick={() => navigate('/faqs')}
        className="text-night-light mr-2 cursor-pointer font-bold hover:text-night"
      >
        FAQs
      </p>
    </div>
  );
};
