import { Header } from '../components/Header';

export const Investors = () => {
  return (
    <div className="p-[25px] md:p-4 min-h-[92vh]  flex flex-col  w-screen overflow-x-hidden">
      <Header />
      <div className="flex flex-1 flex-col  md:mt-6 items-center">
        <h1 className="text-[70px] md:text-[96px] font-helvetica text-night font-black mt-0 md:mt-6 ">
          Investors
        </h1>
        <p className="text-base sm:text-base md:text-px24 font-medium leading-[120%] text-center md:tracking-[-0.72px] md:leading-[28.8px] font-helvetica text-night  mt-2 md:mt-6 text-center  sm:px-0 hidden md:flex">
          Proudly backed by
          <br />
        </p>
        <div className="flex  flex-col flex-wrap items-center justify-evenly xl2:justify-center md:flex-row mt-6 md:mx-8 w-full">
          <img
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[237px] h-[138px] xl:mr-2"
            onClick={() => {
              window.open('https://www.animocabrands.com/', '_blank');
            }}
            src={require('../assets/investors/animoca.png')}
            alt="investor"
          />

          <img
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[166px] h-[138px] xl:mr-2"
            onClick={() => {
              window.open('https://www.samsungnext.com/', '_blank');
            }}
            src={require('../assets/investors/samsung.png')}
            alt="investor"
          />
          <img
            onClick={() => {
              window.open('https://hashkey.capital/', '_blank');
            }}
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[243px] h-[138px] xl:mr-2"
            src={require('../assets/investors/hashkey.png')}
            alt="investor"
          />
          <img
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[237px] h-[138px] xl:mr-2"
            onClick={() => {
              window.open('https://dcg.co/', '_blank');
            }}
            src={require('../assets/investors/digital.png')}
            alt="investor"
          />
          <img
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[237px] h-[138px] xl:mr-2"
            onClick={() => {
              window.open('https://laserdigital.com/', '_blank');
            }}
            src={require('../assets/investors/laserdigital.png')}
            alt="investor"
          />
        </div>
        <div className="flex flex-col xl2:justify-center items-center  flex-wrap justify-evenly  md:flex-row w-full">
          <img
            onClick={() => {
              window.open('https://cyphercapital.com/', '_blank');
            }}
            className="object-contain cursor-pointer w-[100%] w-[219px] h-[138px]"
            src={require('../assets/investors/cypher.png')}
            alt="investor"
          />
          <img
            onClick={() => {
              window.open('https://www.gbv.capital/', '_blank');
            }}
            className="object-contain cursor-pointer w-[100%] w-[219px] h-[138px]"
            src={require('../assets/investors/gbv.png')}
            alt="investor"
          />
          <img
            onClick={() => {
              window.open('https://tokenbaycapital.com/', '_blank');
            }}
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[422px] h-[138px] xl:mr-2"
            src={require('../assets/investors/token.png')}
            alt="investor"
          />
          <img
            onClick={() => {
              window.open('https://momentum6.com/', '_blank');
            }}
            className="xl2:mr-[80px] object-contain cursor-pointer w-[100%] w-[316px] h-[138px] xl:mr-2"
            src={require('../assets/investors/momentum.png')}
            alt="investor"
          />
        </div>
      </div>
    </div>
  );
};
