import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { DailyTasks } from '../components/DailyTasks';
import { Nav } from '../components/Nav';
import { Header } from '../components/Header';
import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import CountdownTimer from '../components/CountdownTimer';
import { DESKTOP_VIDEO, MOBILE_VIDEO } from '../constants';
import VIDEO_PLACEHOLDER from '../assets/placeholder.png';

const D_DAY = new Date('Nov 30, 2023 00:00:00').getTime();

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [show, setShow] = useState(false);

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(40%)' },
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translateY(0)' : 'translateY(40%)',
    },
    config: { duration: 600 }, // Adjust the duration (milliseconds) of the animation
  });

  const springPropsText = useSpring({
    from: { opacity: 0, transform: 'translateY(40%)' },
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translateY(0)' : 'translateY(40%)',
    },
    config: { duration: 300 }, // Adjust the duration (milliseconds) of the animation
  });

  useEffect(() => {
    // Trigger the animation after the component has mounted
    setShow(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // After loading, show the video
    }, 3000);
  }, []);

  return (
    <div className="overflow-x-hidden overflow-y-auto">
      <div className="min-h-screen overflow-y-hidden  md:w-screen  bg-mint flex-col flex items-center p-[25px] md:p-4 md:px-8 xl2:px-10 md:flex-row font-helvetica">
        <div className="md:hidden w-full">
          <Header hideLogo />
        </div>
        <animated.div
          style={springPropsText}
          className="flex flex-1 flex-col items-start md:ml-0  md:p-0"
        >
          <Logo />
          <p className="leading-[50px] tracking-[-0.58px] md:leading-[60.9px] font-helvetica text-night text-xl1 xl3:text-xl3 md:text-xl2 font-black mt-2">
            The <span className="text-primary">chat solution</span>
            <br />
            for {''}
            <span className="text-primary">web3</span> people.
          </p>
          <p className="font-helvetica w-[80%] xl3:text-px24 xl3:mt-4  text-night text-base mt-2  ">
            The most fun you’ll have on a wallet-to-wallet messaging app.
          </p>
          <div>
            <button
              onClick={() => {
                window.open('https://beoble.app', '_blank');
              }}
              style={{ width: 'fit-content' }}
              className="font-helvetica flex bg-primary font-bold text-base  text-white rounded-xl cursor-pointer mt-[28px] flex items-center rounded-[12px] px-2 p-1  enabled:transform enabled:transition-transform enabled:hover:scale-110 md:mb-4 plausible-event-name=SignUpForBeta disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Launch App
              <Arrow />
            </button>
            <div
              style={{ marginBottom: 30, marginLeft: 5 }}
              className="text-primary font-bold md:mt-[-20px] mt-[12px]"
            >
              <CountdownTimer />
            </div>
          </div>
          <Nav />
        </animated.div>
        <animated.div
          style={springProps}
          className="flex xs:hidden md:ml-2 md:flex flex-1 flex-col"
        >
          <video
            onClick={() => setShowControls(true)}
            muted
            loop
            autoPlay={true}
            controls={showControls}
            playsInline={true}
            className="object-contain drop-shadow"
            placeholder={VIDEO_PLACEHOLDER}
            poster={VIDEO_PLACEHOLDER}
          >
            <source src={`${DESKTOP_VIDEO}#t=0.1`} type="video/mp4" />
          </video>
        </animated.div>
        <animated.div
          style={springProps}
          className="flex md:hidden flex-1 flex-col"
        >
          <video
            loop
            onClick={() => setShowControls(true)}
            muted
            autoPlay={true}
            controls={showControls}
            playsInline={true}
            className="object-contain drop-shadow"
            placeholder={VIDEO_PLACEHOLDER}
            poster={VIDEO_PLACEHOLDER}
          >
            <source src={`${MOBILE_VIDEO}#t=0.1`} type="video/mp4" />
          </video>
        </animated.div>
      </div>
      <div className="overflow-y-hidden min-h-screen w-screen flex-col flex items-center justify-center pb-3 md:p-[206px]  md:flex-row">
        <div className="flex flex-col items-start mt-4 md:mt-0 md:order-2 xl1:ml-[-100px]">
          <img
            className="w-[70px]"
            alt={'cat'}
            src={require('../assets/cat-logo.png')}
          />
          <p className="font-helvetica text-night text-xl1 xl3:text-xl3 md:text-xl2 font-black tracking-[-0.58px] xl2:leading-[70.9px] xl3:leading-[75.9px] md:leading-[60.9px] leading-[105%] mt-[28px] ">
            <span className="font-helvetica text-primary">Collect points</span>
            <br />
            while chatting
          </p>
          <p className="leading-[120%] xl3:text-px24 xl3:mt-4 font-helvetica text-night text-base mt-[8px] font-helvetica">
            Invite your friends to chat on beoble,
            <br /> and collect cute cats every time you hangout!
          </p>
          <div>
            {/* <div
              onClick={() =>
                window.open('https://forms.gle/sfD9kkojae7N8Pkk7', '_blank')
              }
              style={{ width: 'fit-content' }}
              className="font-helvetica flex bg-primary font-bold text-base  text-white rounded-xl cursor-pointer mt-[28px] flex items-center rounded-[12px] px-2 p-1  transform transition-transform hover:scale-110 plausible-event-name=SignUpForBeta"
            >
              Sign up for beta
              <Arrow />
            </div> */}
            <button
              onClick={() => {
                window.open('https://beoble.app', '_blank');
              }}
              style={{ width: 'fit-content' }}
              className="font-helvetica flex bg-primary font-bold text-base  text-white rounded-xl cursor-pointer mt-[28px] flex items-center rounded-[12px] px-2 p-1  enabled:transform enabled:transition-transform enabled:hover:scale-110 md:mb-4 plausible-event-name=SignUpForBeta disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Launch App
              <Arrow />
            </button>
          </div>
        </div>
        <DailyTasks />
      </div>
    </div>
  );
};
