export const TeamImage = ({
  children,
  name,
  title,
}: {
  children: any;
  name: string;
  title: string;
}) => {
  return (
    <div className="bg-day-light cursor-pointer rounded-[40px] shadow-md w-[320px]  mb-2 md:mb-0 mr-0 md:mr-6 p-2">
      <div className="overflow-hidden rounded-[40px]">{children}</div>
      <div className="flex items-center">
        <div
          className={`flex items-center px-1 bg-mint ml-1 mt-[12px] rounded-[12px] py-[4px] px-1  cursor-pointer bg-mint mb-0`}
        >
          <img
            className="w-[16px]"
            alt="cat"
            src={require('../assets/blue-cat.png')}
          />
          <p className={'text-primary text-[12px]'}>
            {''}
            {name}
          </p>
        </div>
        <div
          className={`flex items-center px-1 bg-day ml-1 mt-[12px] rounded-[12px] py-[4px] px-1 cursor-pointer bg-day mb-0`}
        >
          <p className={'text-primary text-[12px]'}>{title}</p>
        </div>
      </div>
    </div>
  );
};
