import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const VideoAnimation = ({ onAnimationComplete }: any) => {
  const springPropsVideo = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0.2 },
    config: { duration: 500 }, // Adjust the duration as needed
    onRest: onAnimationComplete,
    delay: 2500,
  });

  useEffect(() => {
    // Add a delay before starting the fade-out animation (3 seconds in this case)
    const timer = setTimeout(() => {
      onAnimationComplete();
    }, 3000);

    // Clear the timer to prevent the animation from running too soon
    return () => clearTimeout(timer);
  }, [onAnimationComplete]);

  return (
    <animated.div
      className={'w-full h-[100vh] flex align-center justify-center'}
    >
      <animated.video
        style={springPropsVideo}
        muted
        autoPlay={true}
        playsInline={true}
        className="object-contain w-[600px]"
      >
        <source src={require('../assets/logo-animated.mp4')} type="video/mp4" />
      </animated.video>
    </animated.div>
  );
};

export default VideoAnimation;
