import { Nav } from './Nav';
export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="mx-[26px] md:mx-6 my-2 mb-4 flex-col md:flex-row flex flex-1 justify-between border-t border-[#D9D9D9] pt-2">
        <p className="font-helvetica text-night-light">
          ©2023 beoble. All rights reserved.
        </p>
        <Nav />
      </div>
    </div>
  );
};
