import { useState } from 'react';
import { Header } from '../components/Header';
import { HoverableImage } from '../components/HoverableImage';
import { TeamImage } from '../components/TeamImage';
import sung from '../assets/team/sungmin.png';
import sang from '../assets/team/sang.png';
import ch from '../assets/team/ch.png';
import jungwoo from '../assets/team/jungwoo.png';
import carina from '../assets/team/carina.png';
import philemon from '../assets/team/philemon.png';

export const Teams = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalObj, setSelectedModalObj] = useState({
    name: 'Sungmin Cho',
    title: 'Co-founder, CEO',
    content:
      'Most recently at Citadel, Goldman Sachs. Previously led system design of a bitcoin price prediction service at Lenovo.',
    twitter: '',
    linkedin: '',
    image: sung,
  });
  const [twitterHover, setTwitterHover] = useState(false);
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [closeHover, setCloseHover] = useState(false);

  const closeSrc = closeHover
    ? require('../assets/close-hover.png')
    : require('../assets/close.png');
  const linkedinSrc = linkedinHover
    ? require('../assets/linkedin-black.png')
    : require('../assets/linkedin24.png');

  const twitterSrc = twitterHover
    ? require('../assets/twitter-black.png')
    : require('../assets/twitter24.png');

  const handleClose = () => setIsModalOpen(false);

  return (
    <div className="p-[25px] md:p-4 min-h-[94vh]  flex flex-col  w-screen overflow-x-hidden">
      <Header />
      <div className="flex flex-1 flex-col  md:mt-6 items-center">
        <h1 className="text-[70px] md:text-[96px] font-helvetica text-night font-black mt-0 md:mt-6 ">
          Team
        </h1>
        <p className="text-base sm:text-base md:text-px24 font-medium leading-[120%] text-center md:tracking-[-0.72px] md:leading-[28.8px] font-helvetica text-night  mt-2 md:mt-6 text-center  sm:px-0 hidden md:flex">
          beoble is a small but growing team of creatives and builders.
          <br />
          Right now we are the most excited about making Web3 messaging fun!
        </p>
        <p className="text-base sm:text-base md:text-px24 font-medium leading-[120%] text-center md:tracking-[-0.72px] md:leading-[28.8px] font-helvetica text-night  mt-2 md:mt-6 text-center  sm:px-0 md:hidden">
          beoble is a small but growing team of creatives and builders. Right
          now we are the most excited about making Web3 messaging fun!
        </p>
        <div className="flex justify-between xl:w-[30%] xl2:w-[25%] w-[90%] mt-6">
          <div className="ml-3 md:ml-0">
            <HoverableImage
              src={require('../assets/cats/cat-5.png')}
              hoverSrc={require('../assets/cats/cat-1-active.png')}
              alt="Cat 1"
            />
          </div>
          <HoverableImage
            src={require('../assets/cats/cat-1.png')}
            hoverSrc={require('../assets/cats/cat-2-active.png')}
            alt="Cat 1"
          />
          <HoverableImage
            src={require('../assets/cats/cat-2.png')}
            hoverSrc={require('../assets/cats/cat-3-active.png')}
            alt="Cat 1"
          />
          <div className="hidden md:block">
            <HoverableImage
              src={require('../assets/cats/cat-3.png')}
              hoverSrc={require('../assets/cats/cat-4-active.png')}
              alt="Cat 1"
            />
          </div>
          <div className="hidden md:block">
            <HoverableImage
              src={require('../assets/cats/cat-4.png')}
              hoverSrc={require('../assets/cats/cat-5-active.png')}
              alt="Cat 1"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row mt-6">
          <TeamImage name="Sungmin Cho" title="Co-Founder, CEO">
            <img
              className="h-[270px] w-[320px]  object-cover rounded-4xl transform transition-transform hover:scale-x-125 hover:scale-y-125"
              src={sung}
              alt={'sung'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedModalObj({
                  name: 'Sungmin Cho',
                  title: 'Co-founder, CEO',
                  content:
                    'Most recently at Citadel, Goldman Sachs. Previously led system design of a bitcoin price prediction service at Lenovo.',
                  twitter: 'https://twitter.com/sungmin_eth',
                  linkedin: 'https://www.linkedin.com/in/sung-min-cho/',
                  image: sung,
                });
              }}
            />
          </TeamImage>
          <TeamImage name="Chanhyeok Yim" title="Co-Founder">
            <img
              className="h-[270px] w-[320px] object-cover rounded-4xl transform transition-transform hover:scale-125"
              src={ch}
              alt={'CH'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedModalObj({
                  name: 'Chanhyeok Yim',
                  title: 'Co-founder',
                  content:
                    'Previously developed risk management tools at JP Morgan.',
                  twitter: '',
                  linkedin: 'https://www.linkedin.com/in/chanhyeokyim/',
                  image: ch,
                });
              }}
            />
          </TeamImage>
          <TeamImage name="Jungwoo Yun" title="Co-Founder">
            <img
              className="h-[270px] w-[320px] object-cover rounded-4xl transform transition-transform hover:scale-125"
              src={jungwoo}
              alt={'Jungwoo'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedModalObj({
                  name: 'Jungwoo Yun',
                  title: 'Co-founder',
                  content:
                    'Previously co-founded Madboy games, and has various experiences in building consumer tech applications.',
                  twitter: '',
                  linkedin: 'https://www.linkedin.com/in/jungwooyun/',
                  image: jungwoo,
                });
              }}
            />
          </TeamImage>
        </div>
        <div className="flex flex-col md:flex-row mt-4">
          <TeamImage name="Carina Yu" title="Product Designer">
            <img
              className="h-[270px] w-[320px] object-cover rounded-4xl transform transition-transform hover:scale-125"
              src={carina}
              alt={'Carina'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedModalObj({
                  name: 'Carina Yu',
                  title: 'Product Designer',
                  content:
                    'Previously at Microsoft. Degree in Interaction design at California College of the Arts.',
                  twitter: 'https://twitter.com/carinify',
                  linkedin: 'https://www.linkedin.com/in/carina-yu-design/',
                  image: carina,
                });
              }}
            />
          </TeamImage>

          <TeamImage name="Sangyeop Jung" title="Engineer">
            <img
              className="h-[270px] w-[320px] object-cover rounded-4xl transform transition-transform hover:scale-125"
              src={sang}
              alt={'Sang'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedModalObj({
                  name: 'Sangyeop Jung',
                  title: 'Software Engineer',
                  content: 'Previously at Goldman Sachs.',
                  twitter: '',
                  linkedin: 'https://www.linkedin.com/in/sangyeopjung/',
                  image: sang,
                });
              }}
            />
          </TeamImage>
        </div>
        <div className="flex flex-col md:items-center items-start">
          <h1 className="text-xl2  sm:text-xl2 md:text-xl2 font-helvetica text-night font-black mt-2 md:mt-6">
            Say hi!
          </h1>
          <p className="font-helvetica text-night my-[20px] text-[16px] font-medium tracking-[-0.72px] leading-[120%] md:text-center">
            We are not actively recruiting at the moment, but if you feel
            connected to our mission,
            <br /> we would love to hear from you! Bonus points if you message
            us on beoble ;)
          </p>
          <a
            target="_blank"
            className="font-helvetica flex bg-primary font-bold  text-white rounded-xl p-1 cursor-pointer  flex items-center text-base mb-4 rounded-2xl px-2 p-1  transform transition-transform hover:scale-110"
            href="mailto:career@beoble.io"
            rel="noreferrer"
          >
            Drop a message 👋
          </a>
        </div>
      </div>
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        <div
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
          }}
          className="relative bg-white flex-col md:flex-row flex rounded-[40px] shadow-md p-3 max-w-[600px]"
        >
          <img
            className="w-[6%] md:top-[-30px] md:right-[-30px] md:absolute fixed top-[20px] right-[15px] self-end cursor-pointer"
            alt="linkedin"
            onClick={handleClose}
            src={closeSrc}
            onMouseEnter={() => setCloseHover(true)}
            onMouseLeave={() => setCloseHover(false)}
          />
          <div className="overflow-hidden rounded-[50px] flex-col items-center flex">
            <img
              className="w-[248px] object-contain rounded-3xl"
              src={selectedModalObj.image}
              alt={'Sang'}
            />
          </div>
          <div className="flex md:ml-2 justify-center flex-col">
            <div className="flex mb-1 my-3">
              <div
                className={`flex items-center px-1 bg-mint my-[12px] rounded-[12px] py-[4px] px-1  cursor-pointer bg-mint`}
              >
                <img
                  className="w-[20px]"
                  alt="cat"
                  src={require('../assets/blue-cat.png')}
                />
                <p className={'text-primary text-[9px] md:text-[11px]'}>
                  {selectedModalObj.name}
                </p>
              </div>
              <div
                className={`flex items-center px-1 bg-day ml-1 my-[12px] rounded-[12px] py-[4px] px-2 cursor-pointer bg-day`}
              >
                <p className={'text-primary text-[9px] md:text-[11px]'}>
                  {selectedModalObj.title}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-base max-w-[270px]">
                {selectedModalObj.content}
              </p>
            </div>
            <div className="flex mt-2">
              {selectedModalObj.twitter && (
                <img
                  onClick={() =>
                    window.open(selectedModalObj.twitter, '_blank')
                  }
                  className="w-[11%] cursor-pointer"
                  alt="twitter"
                  src={twitterSrc}
                  onMouseEnter={() => setTwitterHover(true)}
                  onMouseLeave={() => setTwitterHover(false)}
                />
              )}
              <img
                onClick={() => window.open(selectedModalObj.linkedin, '_blank')}
                className="w-[11%] cursor-pointer"
                alt="linkedin"
                src={linkedinSrc}
                onMouseEnter={() => setLinkedinHover(true)}
                onMouseLeave={() => setLinkedinHover(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Modal = ({ isOpen, handleClose, children }: any) => {
  return (
    isOpen && (
      <div className="bg-opacity-[0.84] cursor-pointer bg-night modal w-[100%] h-[100%] inset-0 bg-black flex justify-center items-center">
        <div
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
            handleClose();
          }}
          className="p-4 md:p-6 rounded-md w-full h-full flex flex-col justify-center items-center"
        >
          {children}
        </div>
      </div>
    )
  );
};
