import './App.css';
import { Home } from './screens/Home';
import { Teams } from './screens/Teams';
import { FAQs } from './screens/FAQs';
import { Footer } from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import VideoAnimation from './components/VideoAnimation';
import { Investors } from './screens/Investors';

function App() {
  const [loading, setLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the delay as needed (3 seconds in this case)
  }, []);

  const handleAnimationComplete = () => {
    setShowVideo(false);
  };

  return (
    <Router>
      <div className="app-container">
        {loading ? (
          <div className="video-container">
            <VideoAnimation onAnimationComplete={handleAnimationComplete} />
          </div>
        ) : (
          <div className="content-container">
            <Routes>
              <Route index element={<Home />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/investors" element={<Investors />} />
            </Routes>
            <Footer />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
