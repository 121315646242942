import { useEffect, useRef, useState } from 'react';
import { Header } from '../components/Header';
import { HoverableImage } from '../components/HoverableImage';
import { useSpring, animated } from 'react-spring';

export const FAQs = () => {
  const faqs = [
    {
      question: 'What is beoble?',
      answer:
        'In short, beoble is the Whatsapp for Web3. Our chat app allows crypto wallet owners to message each other, and our integration toolkit allows Dapps to add a great communication experience to their products fast.',
    },
    {
      question: 'Which blockchain can I log in to beoble with?',
      answer:
        'beoble is currently supporting all EVM (e.g., Ethereum, Binance Smart Chain, Polygon, etc.), and we have plans to expand to other blockchains like Solana, Cosmos, Flow, Aptos, and Sui in the coming future.',
    },
    {
      question: 'How does beoble deliver messages between wallets?',
      answer:
        'We deliver messages via "Communication Delivery Graph", which is a decentralized encryption network designed to protect the privacy of our users. ',
      link: 'https://docs.beoble.io/architecture/communication-delivery-graph-cdg',
    },
    {
      question: 'Where will my data be stored?',
      answer:
        'We use both decentralized storage (e.g. IPFS, Arweave, etc.) and your local device. No messaging data will be stored on-chain due to security risks.',
    },
    {
      question: 'How do I report an issue?',
      answer:
        'Email us at support@beoble.io, or message @beoble or @Sung on beoble.',
    },
  ];

  useEffect(() => {
    window.addEventListener('scroll', () => {
      // Calculate the current scroll position
      const container = document.querySelector('.container'); // Replace with your actual container element

      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Determine if the scroll position is at the end of the content
      const isAtEnd = scrollY + windowHeight >= documentHeight;

      // Trigger your action when the scroll position is at the end
      if (isAtEnd) {
        console.log(documentHeight);
        // Your action here, e.g., loading more content or displaying a message
        setTimeout(() => {
          window.scrollTo({ top: documentHeight - 10, behavior: 'smooth' });
        }, 1);
      }
    });
  }, []);

  return (
    <div className="p-[25px] md:p-4   flex flex-col  w-screen overflow-x-hidden">
      <Header />
      <div className="flex flex-1 flex-col  md:mt-6 items-center">
        <h1 className="text-[70px] md:text-[96px] font-helvetica text-night font-black mt-0 md:mt-6 ">
          FAQs
        </h1>
        <p className="text-base sm:text-base md:text-px24 font-medium leading-[120%] text-center md:tracking-[-0.72px] md:leading-[28.8px] font-helvetica text-night  mt-2 md:mt-6 text-center  sm:px-0 hidden md:flex">
          For questions that are not included here,
          <br /> email us at support@beoble.io, or DM us on beoble for faster
          response.
        </p>
        <div className="flex justify-between xl:w-[30%] xl2:w-[25%] w-[90%] mt-6">
          <div className="ml-3 md:ml-0">
            <HoverableImage
              src={require('../assets/cats/cat-5.png')}
              hoverSrc={require('../assets/cats/cat-1-active.png')}
              alt="Cat 1"
            />
          </div>
          <HoverableImage
            src={require('../assets/cats/cat-1.png')}
            hoverSrc={require('../assets/cats/cat-2-active.png')}
            alt="Cat 1"
          />
          <HoverableImage
            src={require('../assets/cats/cat-2.png')}
            hoverSrc={require('../assets/cats/cat-3-active.png')}
            alt="Cat 1"
          />
          <div className="hidden md:block">
            <HoverableImage
              src={require('../assets/cats/cat-3.png')}
              hoverSrc={require('../assets/cats/cat-4-active.png')}
              alt="Cat 1"
            />
          </div>
          <div className="hidden md:block">
            <HoverableImage
              src={require('../assets/cats/cat-4.png')}
              hoverSrc={require('../assets/cats/cat-5-active.png')}
              alt="Cat 1"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center  mt-8">
        <div className="flex text-night items-center flex-col md:w-[50%] w-[100%]">
          {faqs.map((faq, index) => (
            <AccordionItem faq={faq} />
          ))}
        </div>
      </div>
    </div>
  );
};

const AccordionItem = ({ faq }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: isOpen ? contentMaxHeight + 20 : 0, opacity: isOpen ? 1 : 0 },
  });

  const ref = useRef();

  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref &&
        ref.current &&
        setContentMaxHeight((ref.current as any).scrollHeight - 20);
    };

    calcContentMaxHeight();

    window.addEventListener('resize', () => calcContentMaxHeight());

    return () => window.removeEventListener('resize', calcContentMaxHeight);
  }, [ref, contentMaxHeight, isOpen]);

  return (
    <div
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      className="overflow-hidden mt-2 cursor-pointer border-b border-[#E6EEF5] w-full"
    >
      <div className="w-full text-left bg-gray-200 rounded-md focus:outline-none focus:ring-2 font-extrabold text-px24 font-medium tracking-[-0.72px] leading-[28.8px] flex items-center hover:text-primary">
        <img
          src={require('../assets/add.png')}
          alt={isOpen ? 'close' : 'add'}
          className={`w-[24px] object-contain mr-2 transform ${
            isOpen
              ? 'rotate-45 transition-transform delay-100'
              : 'rotate-0 transition-transform delay-100'
          }`}
        />
        {faq.question}
      </div>
      <animated.div
        ref={ref as any}
        style={{ height, opacity }}
        className={`bg-gray-100 cursor-auto rounded-md break-words ml-[40px] mx-[20px] text-base ${
          isOpen ? 'my-2' : 'my-1'
        }`}
      >
        {faq.answer}
        {faq.link && (
          <a
            target={'_blank'}
            className={'text-primary'}
            href={faq.link}
            rel="noreferrer"
          >
            {' '}
            Learn more about it here.
          </a>
        )}
      </animated.div>
    </div>
  );
};
