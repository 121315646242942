import React, { useState } from 'react';

interface HoverableImageProps {
  src: string;
  hoverSrc: string;
  alt: string;
}

export const HoverableImage: React.FC<HoverableImageProps> = ({
  src,
  hoverSrc,
  alt,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <img
      className={`w-[65px] h-[72px]`}
      src={isHovered ? hoverSrc : src}
      alt={alt}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};
