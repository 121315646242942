import { useMemo } from 'react';
import { ProgressBar } from './ProgressBar';

export const LevelPoints = [100, 1000, 3000, 5000, 10000, 50000];

export const getLevelInfo = (score: number) => {
  const reduced: number[] = [];
  LevelPoints.reduce((sum, cur) => {
    const reducedPoint = sum + cur;
    reduced.push(reducedPoint);
    return sum + cur;
  }, 0);
  let level = 0;
  reduced.every((reducedPoint) => {
    if (score < reducedPoint) return false;
    level++;
    return true;
  });
  const curScore = score - (reduced[level - 1] ?? 0);
  const curLevelPoint = LevelPoints[level];
  const progress = curScore / curLevelPoint;
  return {
    level,
    curScore,
    curLevelPoint,
    progress,
  };
};

export interface LevelProps {
  score: number;
  name?: string;
}

// TODO: Write test for utility function
export const formatNumber = (num: number, decimalPlace = 2) => {
  if (num.toString().split('.')[1]?.length > decimalPlace) {
    return num.toFixed(decimalPlace);
  } else return num;
};

export const Level: React.FC<LevelProps> = ({ score, name }) => {
  const { level, curLevelPoint, curScore, progress } = useMemo(
    () => getLevelInfo(score),
    [score],
  );

  return (
    <div className="flex flex-col xl2:text-medium xl3:text-medium space-y-1.5 w-full select-none mt-2">
      <ProgressBar percentage={progress} />
      <div className="flex justify-between w-full">
        <p>{`${formatNumber(curScore)}/${curLevelPoint} cats collected`}</p>
        <p>Level {level}</p>
      </div>
    </div>
  );
};
