import { useEffect, useState } from 'react';
import { ReactComponent as Cat } from '../assets/cat.svg';
import { ReactComponent as Handle } from '../assets/handle.svg';
import { Level } from './Level';

export const DailyTasks = () => {
  const [tilt, setTilt] = useState(0);
  const [dailyTasks, setDailyTasks] = useState(15);
  const [score, setScore] = useState(65);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage =
        window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight);
      const maxTilt = -3; // Maximum tilt in degrees
      setTilt(scrollPercentage * maxTilt);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="flex  md:w-[65%] flex-col justify-between items-stretch md:order-1">
      <div
        style={{
          transform: `rotate(${+tilt}deg)`,
          transition: 'transform 0.2s', // smoothens the rotation change
        }}
        className="w-[90%] xl2:ml-[100px] xl3:ml-[200px] xl1:w-[400px] md:w-[337px] xl3:w-[450px]  self-start mt-2  p-2 bg-white rounded-xl shadow-md ml-2"
      >
        <h2 className="text-xl xl3:text-px24 font-bold mb-2">Daily Tasks</h2>
        <ul>
          <ListItem
            value={10}
            setDailyTasks={setDailyTasks}
            setScore={setScore}
          >
            <span className="xl3:text-medium font-medium">
              Send 10 messages
            </span>
          </ListItem>
          <ListItem
            value={10}
            setDailyTasks={setDailyTasks}
            setScore={setScore}
          >
            <span className="font-medium xl3:text-medium">
              Send a message in #general
            </span>
          </ListItem>
          <ListItem
            value={10}
            setDailyTasks={setDailyTasks}
            setScore={setScore}
            lastItem={true}
          >
            <span className="font-medium xl3:text-medium">
              Invite your friends to beoble
            </span>
          </ListItem>
        </ul>
      </div>
      <div
        style={{
          transform: `rotate(${-tilt}deg)`,
          transition: 'transform 0.2s', // smoothens the rotation change
        }}
        className="self-center  w-[90%]  xl1:w-[450px] md:w-[330px] md:w-[400px] xl3:w-[500px] mt-2 md:mt-0 p-2  bg-day-light rounded-xl shadow-md items-center flex flex-col mt-4"
      >
        <img
          alt={'avatar'}
          className={'w-[30%]'}
          src={require('../assets/large_avatar.png')}
        />
        <Handle className="self-start " />
        <Level score={score} />
        <div className="w-[100%] h-[120px] md:h-[100px] font-sfpro  p-1 flex flex-col justify-center bg-[#B39AFF33] rounded-[4px] shadow-md mt-1 p-2">
          <p className="text-night-light font-bold ">Total Reward points</p>
          <p className="font-sfpro text-night text-px24 leading-[36px] md:text-lg font-bold">
            {score}
          </p>
        </div>
        <div className="flex flex-1 mt-1 items-start w-[100%]">
          <div className="w-[100%] h-[90px] md:h-[80px]  p-1 flex flex-col justify-center bg-white rounded-[4px] shadow-md mt-1  mr-2">
            <p className="font-sfpro text-night text-px24 leading-[36px] md:text-lg font-bold">
              +{dailyTasks}
            </p>
            Daily Tasks
          </div>
          <div className="w-[100%] h-[90px] md:h-[80px]  p-1 flex flex-col justify-center bg-white rounded-[4px] shadow-md mt-1">
            <p className="font-sfpro text-night text-px24 leading-[36px] md:text-lg font-bold">
              +10
            </p>
            Weekly Tasks
          </div>
        </div>
        <div className="flex flex-1 w-[100%] items-start">
          <div className="w-[100%] h-[90px] md:h-[80px]  p-1 flex flex-col justify-center bg-white rounded-[4px] shadow-md mt-1 mr-2">
            <p className="font-sfpro text-night text-px24 leading-[36px] md:text-lg font-bold">
              +5
            </p>
            Bonus reward
          </div>
          <div className="w-[100%] h-[90px] md:h-[80px]  p-1 flex flex-col justify-center  text-base bg-white rounded-[4px] shadow-md mt-1">
            <p className="font-sfpro text-night text-px24 leading-[36px] md:text-lg font-bold">
              +35
            </p>
            Chatroom earning
          </div>
        </div>
      </div>
    </div>
  );
};

const ListItem = ({
  children,
  value,
  setDailyTasks,
  setScore,
  lastItem,
}: any) => {
  const [clicked, setClicked] = useState(false);

  return (
    <li
      className={`flex items-center mb-2 justify-between cursor-pointer transform transition-transform hover:scale-105 ${
        lastItem ? '' : 'border-b border-night-light'
      } pb-1`}
      onClick={() => {
        setClicked(!clicked);
        setDailyTasks((prev: any) => prev + (!clicked ? value : -value));
        setScore((prev: any) => prev + (!clicked ? value : -value));
      }}
    >
      {children}
      <div
        className={`flex items-center  bg-mint ml-1 rounded-[4px] p-[4px] px-1  cursor-pointer ${
          clicked ? 'bg-night' : 'bg-mint'
        }`}
      >
        {!clicked && <Cat />}
        <p className={`text-[12px] ${!clicked ? 'text-night' : 'text-mint'}`}>
          {clicked ? `+${value} 🎉 ` : `x${value}`}
        </p>
      </div>
    </li>
  );
};
