import React from 'react';

export interface ProgressBarProps {
  percentage: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  if (percentage > 1 || percentage < 0) return null;

  const widthStyle = {
    width: `${percentage * 100}%`,
  };
  return (
    <div className="border-2  border-night rounded-full bg-night h-[12px] flex items-center">
      <div
        style={widthStyle}
        className="bg-mint  rounded-full transition-width duration-300  h-[8px] ease-in-out"
      ></div>
    </div>
  );
};
